// src/directives/cpfCnpjMask.js
export default {
  beforeMount(el) {
    el.addEventListener('input', function (e) {
      // Captura a tecla pressionada
      const key = e.inputType;

      let value = e.target.value.replace(/\D/g, '');

      if (value.length <= 11) {
        // Aplicar máscara de CPF
        value = value
          .replace(/^(\d{3})(\d)/, '$1.$2')
          .replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1-$2');
      } else {
        // Aplicar máscara de CNPJ
        value = value
          .replace(/^(\d{2})(\d)/, '$1.$2')
          .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          .replace(/\.(\d{3})(\d)/, '.$1/$2')
          .replace(/(\d{4})(\d)/, '$1-$2');
      }

      // Preserva a posição do cursor somente para teclas de deleção
      if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
        const cursorPosition = e.target.selectionStart;
        e.target.value = value;
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      } else {
        e.target.value = value;
      }
    });
  }
};
