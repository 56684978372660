
import axios from 'axios';
export default {
  methods: {
    async retornaUrlArquivoDoUploadS3(fileBlob, token) {
      try {
        console.log("FILE BLOB", fileBlob);

        // Verificar o tipo de arquivo (opcional)
        const fileType = fileBlob.type; 
        console.log("Tipo de arquivo:", fileType);

        // Extrair a extensão do arquivo a partir do tipo MIME ou nome do arquivo
        const extension = this.getFileExtension(fileBlob);

        console.log("Extensão do arquivo:", extension);

        // Converter o Blob para base64
        const base64File = await this.convertBlobToBase64(fileBlob);
        console.log("BASE 64 FILE", base64File);

        const response = await axios.post('/api/retornaUrlArquivoDoUploadS3', {
          fileData: base64File, // Envia o arquivo convertido para base64
          fileType, // Enviar o tipo de arquivo (opcional)
          extension, // Enviar a extensão do arquivo
        }, {
          headers: {
            'Authorization': `Bearer ${token}`, // Gerencie o token adequadamente
            'Content-Type': 'application/json', // Enviar como JSON
          },
        });

        if (response.status === 200) {
          return response.data;
        } else {
          throw new Error('Falha ao enviar o arquivo');
        }
      } catch (error) {
        console.error('Erro ao enviar arquivo:', error);
        throw error;
      }
    },

    // Função para converter Blob para base64
    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

    // Função para obter a extensão do arquivo a partir do Blob
    getFileExtension(fileBlob) {
      let extension = '';

      if (fileBlob.name) {
        extension = fileBlob.name.split('.').pop();
      } else if (fileBlob.type) {
        // Extrair apenas a parte principal do MIME type (antes do ';')
        const mimeType = fileBlob.type.split(';')[0].trim();

        const mimeToExt = {
          // Imagens
          'image/jpeg': 'jpg',
          'image/png': 'png',
          'image/gif': 'gif',
          'image/bmp': 'bmp',
          'image/webp': 'webp',
          'image/tiff': 'tiff',
          'image/svg+xml': 'svg',
          'image/x-icon': 'ico',

          // Áudio
          'audio/mpeg': 'mp3',
          'audio/ogg': 'ogg',
          'audio/wav': 'wav',
          'audio/aac': 'aac',
          'audio/x-flac': 'flac',
          'audio/webm': 'webm',

          // Vídeo
          'video/mp4': 'mp4',
          'video/x-msvideo': 'avi',
          'video/mpeg': 'mpeg',
          'video/ogg': 'ogv',
          'video/webm': 'webm',
          'video/quicktime': 'mov',
          'video/x-ms-wmv': 'wmv',
          'video/x-flv': 'flv',

          // Documentos
          'application/pdf': 'pdf',
          'application/msword': 'doc',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
          'application/vnd.ms-excel': 'xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
          'application/vnd.ms-powerpoint': 'ppt',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',

          // Arquivos de texto
          'text/plain': 'txt',
          'text/html': 'html',
          'text/css': 'css',
          'application/json': 'json',
          'application/javascript': 'js',
          'application/xml': 'xml',

          // Arquivos compactados
          'application/zip': 'zip',
          'application/x-rar-compressed': 'rar',
          'application/x-7z-compressed': '7z',
          'application/x-tar': 'tar',
          'application/gzip': 'gz',

          // Outros formatos
          'application/octet-stream': 'bin',
          'application/x-shockwave-flash': 'swf',
          'application/x-font-ttf': 'ttf',
          'application/x-font-woff': 'woff',
          'application/x-font-woff2': 'woff2',
          'application/vnd.apple.pkpass': 'pkpass',
        };

        extension = mimeToExt[mimeType] || '';
      }

      return extension;
    }
  }
}
