const state = {
  breadcrumbs: []
};

const mutations = {
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  }
};

const actions = {
  updateBreadcrumbs({ commit }, route) {
    const breadcrumbs = [];
    route.matched.forEach(route => {
      if (route.meta && route.meta.breadcrumb) {
        breadcrumbs.push({
          text: route.meta.breadcrumb,
          href: route.path
        });
      }
    });
    commit('SET_BREADCRUMBS', breadcrumbs);
  }
};

const getters = {
  breadcrumbs: state => state.breadcrumbs
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
