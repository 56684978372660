// src/directives/phoneMask.js
export default {
  beforeMount(el) {
    let timeout;

    el.addEventListener('input', function (e) {
      clearTimeout(timeout); // Limpa o timeout anterior

      timeout = setTimeout(() => {
        // Captura a tecla pressionada
        const key = e.inputType;

        let value = e.target.value.replace(/\D/g, '');

        if (value.length <= 10) {
          // Aplicar máscara de telefone sem o 9
          value = value
            .replace(/^(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2');
        } else {
          // Aplicar máscara de telefone com o 9
          value = value
            .replace(/^(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{1})(\d{4})(\d)/, '$1 $2-$3');
        }

        // Preserva a posição do cursor somente para teclas de deleção
        if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
          const cursorPosition = e.target.selectionStart;
          e.target.value = value;
          e.target.setSelectionRange(cursorPosition, cursorPosition);
        } else {
          e.target.value = value;
        }

        // Dispara o evento input para notificar mudanças
        const event = new Event('input', { bubbles: true });
        e.target.dispatchEvent(event);
      }, 0); // Timeout de 0 ms para evitar recursão infinita
    });
  }
};
