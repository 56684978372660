<template>
  <nav v-if="breadcrumbs.length" aria-label="breadcrumb" class="d-flex align-items-center justify-content-between mb-3 boxBreadcrumbCi">

    <div class="conteudoBreadcrumb d-flex align-items-center justify-content-between flex-wrap">
      <!-- Begin BreadCrumb -->
      <section class="breadcrumbCi itemBreadcrumb d-flex align-items-center" :class="{'possuiTitulo': textoTituloPagina}">
        <a href="/admin/chat" class="homeBreadcrumb">
          <i class="fa fa-house"></i>
        </a>
        <!-- Exibindo passos anteriores -->
        <div v-for="(passo, index) in passosAnterioresBreadCrumb" :key="'anterior-' + index" class="d-flex align-items-center passosAnteriores">
          <div class="separatorBreadcrumb setasCi d-flex align-items-center mx-2">
            <i class="fa fa-angle-right position-relative"></i>
            <i class="fa fa-angle-right position-relative"></i>
          </div>
          <h6 class="m-0 linkPassoAnterior">
            <router-link :to="passo.href">{{ passo.text }}</router-link>
          </h6>
        </div>
        <div v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="d-flex align-items-center passosAtuais">
          <div class="separatorBreadcrumb setasCi d-flex align-items-center mx-2">
            <i class="fa fa-angle-right position-relative"></i>
            <i class="fa fa-angle-right position-relative"></i>
          </div>
          <h6 class="m-0">
            <router-link v-if="index < breadcrumbs.length - 1" :to="breadcrumb.href">
              {{ breadcrumb.text }}
            </router-link>
            <span v-else>
          {{ breadcrumb.text }}
        </span>
          </h6>

          <div class="separatorBreadcrumb setasCi d-flex align-items-center mx-2" v-if="index === breadcrumbs.length - 1 && passoAdicionalBreadCrumb">
            <i class="fa fa-angle-right position-relative"></i>
            <i class="fa fa-angle-right position-relative"></i>
          </div>
          <h6 class="m-0" v-if="index === breadcrumbs.length - 1 && passoAdicionalBreadCrumb">
            {{passoAdicionalBreadCrumb}}
          </h6>
        </div>
      </section>
      <!-- End BreadCrumb -->
      <!-- Begin Sub Título -->
      <div v-if="textoTituloPagina" class="text-right textoTituloPagina">
        <h6 class="m-0 font14">{{textoTituloPagina}}</h6>
      </div>
    </div>
    
    <!-- Begin Vídeo Ajuda -->
    <div v-if="linkVideoAjuda && !config.isWhiteLabel" class="boxVideoAjuda itemBreadcrumb d-flex align-items-center ml-3" @click="exibeVideoAjuda">
      <i class="fa-brands fa-square-youtube mr-2"></i>
      <div class="textosVideoAjuda">
        <p class="m-0 font10">ASSISTA AGORA</p>
        <p class="m-0 font12"><b>{{ textoVideoAjuda }}</b></p>
      </div>
    </div>
    <!-- End Vídeo Ajuda -->
  </nav>
  <!-- Begin modal Video -->
  <div class="modal fade modalVideoIntroducao" tabindex="-1" role="dialog" aria-labelledby="confirmacaoModalLabel" aria-hidden="true" ref="videoAjudaCi">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="fechaModalIntroducao">
            <span aria-hidden="true">&times;</span>
          </button>
          <iframe
            id="videoAjudaCi"
            width="560"
            height="315"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- End modal Video -->
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters('breadcrumb', ['breadcrumbs']),
    ...mapGetters(['config'])
  },
  watch: {
    '$route': {
      immediate: true,
      handler(newRoute) {
        this.updateBreadcrumbs(newRoute);
      }
    }
  },
  props: {
    linkVideoAjuda: {
      type: String,
      default: null
    },
    textoVideoAjuda: {
      type: String,
      default: null
    },
    passoAdicionalBreadCrumb: {
      type: String,
      default: null
    },
    passosAnterioresBreadCrumb: {
      type: Array,
      default: () => []
    },
    textoTituloPagina: {
      type: String,
      default: null
    },
  },
  mounted() {
    this.runjQuery();
  },
  methods:
    {
      ...mapActions('breadcrumb', ['updateBreadcrumbs']),
      fechaModalIntroducao() {
        // Parar e reiniciar o vídeo
        const iframe = $('#videoAjudaCi');
        iframe.attr('src', '');
        jQuery('.boxHomeSemIntegracoes').removeClass('sobrepoe');
      },
      exibeVideoAjuda() {
        var src = this.linkVideoAjuda;
        var iframe = $('#videoAjudaCi');
        iframe.attr('src', '');
        iframe.attr('src', src);
        jQuery('.modalVideoIntroducao').modal('show');
        jQuery('.modal-backdrop').addClass('esconde');
      },
      runjQuery()
      {
        $('body').on('click', '.modalVideoIntroducao', function (a) {
          var iframe = $('#videoAjudaCi');
          iframe.attr('src', '');
          jQuery('.boxHomeSemIntegracoes').removeClass('sobrepoe');
        });
      },
    }

};
</script>

<style scoped>
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin: 0;
}
</style>
